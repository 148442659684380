import { Label } from 'reactstrap';
import Box from '@mui/material/Box';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import TaskHistoryDto from '../../dtos/Task/TaskHistoryDto';

export default function TaskAttachment(props) {
    const { showImages, taskChange } = props;

    const showAttachment = useMemo(() => showImages && taskChange.contentType.includes('image'));

    return(
        <Box
            sx={{
                width: '100%',
                border: '1px solid #ddd',
                margin: '10px',
                backgroundColor: 'white',
                padding: '5px',
                display: 'fixed',
            }}
        >
            <Label
                style={{ fontWeight: 'bold' }}
            >
                {taskChange?.taskChangeType} posted by {taskChange?.taskChangeUser} on {taskChange?.taskChangeTime}
            </Label>
            <br/>
            <a href={taskChange.url} target={'_blank'} rel="noreferrer">{taskChange.taskChange}</a>
            {showAttachment &&
                <img src={taskChange.url} style={{maxWidth: '100%'}}/>
            }

        </Box>
    )
}

TaskAttachment.propTypes = {
    showImages: PropTypes.bool.isRequired,
    taskChange: PropTypes.objectOf(TaskHistoryDto),
}
