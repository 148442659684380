import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button } from 'reactstrap';
import React, { useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function EditCommentModal (props) {
    const {open, cancel, comment, setComment, save, originalValue} = props;

    useEffect(() => {

    }, []);

    return (
        <Dialog
            open={open}
            sx={{'& .MuiDialog-paper': {width: '100%', maxHeight: 435}}}
            >
            <DialogTitle>Edit Comment</DialogTitle>
            <DialogContent dividers>
                <CKEditor
                    style={{height: '400px', width: '100%'}}
                    editor={ClassicEditor}
                    data={comment}
                    onChange={(event, editor) => {
                        setComment(editor.getData());
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => cancel()}>Cancel</Button>
                <Button onClick={save} disabled={originalValue === comment}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
