import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Divider, Input, MenuItem, Select } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import apiService from '../../api/apiService';

export default function RiskControlModal (props) {
    const { onClose, open, controlTypes, hazardId, ...other } = props;
    const [description, setDescription] = useState('');
    const [currentControlType, setCurrentControlType] = useState({});

    const handleCancel = () => {
        resetModal();
        onClose(false);
    };

    const handleSave = async () => {
        if (!description) {
            return;
        }

        if (Object.keys(currentControlType).length === 0) {
            return;
        }

        await apiService.postData('/api/Hazard/hazardControl', {
            HcHazard: hazardId,
            HcDescription: description,
            HcControlType: controlTypes.find(controlType => controlType.ctName === currentControlType).ctId,
        });

        resetModal();
        onClose(true);
    };

    const resetModal = () => {
        setDescription('');
        setCurrentControlType({});
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle variant="h4">Add New Control</DialogTitle>
            <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} />
            <DialogContent>
                <Typography variant="h5">
                    Description
                </Typography>
                <Input
                    type="text"
                    name="department"
                    value={description}
                    sx={{ width: '100%', marginBottom: '30px' }}
                    onChange={(event) => {
                        setDescription(event.target.value);
                    }}
                />
                <Typography variant="h5">
                    Control Type
                </Typography>
                <Select
                    sx={{ width: '100%' }}
                    name="Control Type"
                    value={Object.keys(currentControlType).length > 0 ? currentControlType : ''}
                    onChange={(event) => {
                        setCurrentControlType(event.target.value);
                    }}
                >
                    {controlTypes.map((controlType) => (
                        <MenuItem key={controlType.ctId} value={controlType.ctName}>
                            {controlType.ctName}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} />
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

RiskControlModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    controlTypes: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    hazardId: PropTypes.string.isRequired,
};
