import { Label } from 'reactstrap';
import Box from '@mui/material/Box';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TaskHistoryDto from '../../dtos/Task/TaskHistoryDto';
import TaskCommentButtonBar from './TaskCommentButtonBar';
import ConfirmDeleteDialog from '../Modal/ConfirmDelete';
import { Grid } from '@mui/material';
import authenticationManager from '../../auth/AuthenticationManager';
import EditCommentModal from '../Modal/EditCommentModal';
import TaskApiService from '../../api/TaskApiService';

export default function TaskComment(props) {
    const { showHistory, taskChange, setTaskChange, setSnackbarProperties, handleDeleted } = props;
    const[showDeleteDialog, setShowDeleteDialog] = useState(false);
    const[showEditDialog, setShowEditDialog] = useState(false);

    const [originalValue, setOriginalValue] = useState('');

    const showComment = useMemo(() => taskChange?.taskChangeType === 'comment' || showHistory);

    const taskApiService = new TaskApiService();

    async function handleDeleteComment() {
        setShowDeleteDialog(true);
    }

    function handleEditComment() {
        setShowEditDialog(true);
    }

    async function handleSaveEdit() {
        const response = await taskApiService.updateTaskChange(taskChange.taskChangeId, taskChange);

        if(response && response.success) {
            setSnackbarProperties('Successfully updated comment');
            setShowEditDialog(false);
        } else {
            setSnackbarProperties('Error updating comment', 'error');
        }
    }

    function handleCommentChange(value) {
        setTaskChange(value);
    }

    async function handleConfirmDelete() {
        const response = await taskApiService.deleteTaskChange(taskChange.taskChangeId);

        if(response && response.success) {
            setSnackbarProperties('Successfully deleted comment');
        } else {
            setSnackbarProperties('Error deleting comment', 'error');
        }
        setShowDeleteDialog(false);
        handleDeleted();
    }

    function handleEditCancel() {
        setTaskChange(originalValue);
        setShowEditDialog(false);
    }

    useEffect(() => {
        setOriginalValue(`${taskChange.taskChange}`)
    }, []);

    return(
        <Box
            sx={{
                width: '100%',
                border: '1px solid #ddd',
                margin: '10px',
                backgroundColor: 'white',
                padding: '10px',
                display: showComment ? 'flex' : 'none',
            }
            }>
            <Grid container>
                <Grid item xs={10}>
                    <Label
                        style={{ fontWeight: 'bold' }}>
                        {taskChange?.taskChangeType} posted by {taskChange?.taskChangeUser} on {taskChange?.taskChangeTime}
                    </Label>
                </Grid>
                {authenticationManager.isAdmin() && taskChange?.taskChangeType === 'comment' &&
                    <Grid item xs={2}>
                        <TaskCommentButtonBar handleEditComment={handleEditComment} handleDeleteComment={handleDeleteComment} style={{marginRight: '2px', marginLeft: 'auto'}} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Label dangerouslySetInnerHTML={{ __html: taskChange.taskChange }} />
                </Grid>
            </Grid>

            <EditCommentModal
                open={showEditDialog}
                cancel={handleEditCancel}
                comment={taskChange.taskChange}
                setComment={handleCommentChange}
                save={handleSaveEdit}
                originalValue={originalValue}
            />
            <ConfirmDeleteDialog
                onClose={handleConfirmDelete}
                open={showDeleteDialog}
                title={'Delete Comment'}
                content={'Are you sure you wish to delete this comment?'}
            />
        </Box>
    )
}

TaskComment.propTypes = {
    showHistory: PropTypes.bool.isRequired,
    taskChange: PropTypes.objectOf(TaskHistoryDto)
}
