import apiService from './apiService';

export default class TaskApiService {
    /**
     * Gets a task's details
     * @param taskId
     * @return {Promise<ServiceResponse<TaskDto>>}
     */
    async getTask (taskId) {
        const taskData = await apiService.getData('api/Task/' + taskId);
        return taskData.data;
    }

    /**
     * Gets tasks based on the query
     * @param queryId {number}
     * @return {Promise<ServiceResponse<TaskListResponse>>}
     */
    async getTasksByQuery (queryId) {
        const response = await apiService.getData('api/Task?queryId=' + queryId);
        return response.data.data;
    }

    async deleteTask (taskId) {
        const taskData = await apiService.deleteData('api/Task/' + taskId);
        return taskData.data.data;
    }

    async getNewTask () {
        const newTaskData = await apiService.getData('api/Task/NewTask');
        return newTaskData.data;
    }

    async getNextTaskId (taskId) {
        const nextTaskId = await apiService.getData('api/Task/GetNextTask?currentTaskId=' + taskId);
        return nextTaskId.data.data;
    }

    async getPreviousTaskId (taskId) {
        const previousTaskId = await apiService.getData('api/Task/GetPreviousTask?currentTaskId=' + taskId);
        return previousTaskId.data.data;
    }

    async addNewTask (taskData) {
        const newTask = await apiService.postData('api/Task', taskData);
        return newTask.data;
    }

    async addNewBasicTask (taskData) {
        const newTask = await apiService.postData('api/Task/Basic', taskData);
        return newTask.data;
    }

    async updateTask (taskId, taskData) {
        const taskUpdate = await apiService.putData('api/Task/' + taskId, taskData);
        return taskUpdate.data;
    }

    async addTaskComment (newComment) {
        const taskComment = await apiService.postData('api/Task/AddNewComment', newComment);
        return taskComment.data.data;
    }

    async getTaskStatuses () {
        const taskStatuses = await apiService.getData('api/Task/Statuses');
        return taskStatuses.data.data;
    }

    async getTaskByStatus () {
        const taskByStatus = await apiService.getData('api/Task/TaskByStatus');
        return taskByStatus.data.data;
    }

    async getTaskUser () {
        const taskUser = await apiService.getData('api/Task/user');
        return taskUser.data.data;
    }

    /**
     * Get related tasks for this task
     * @param {number} taskId
     * @returns {Promise<ServiceResponse<Array<TaskRelationshipDto>>>}
     */
    async getRelatedTasks (taskId) {
        const relatedTasks = await apiService.getData('api/Task/taskRelationship/' + taskId);
        return relatedTasks.data;
    }

    /**
     * Add a related task for this task
     * @param {TaskRelationshipDto} relatedTaskData
     * @returns {Promise<ServiceResponse<TaskRelationshipDto>>}
     */
    async addRelatedTask (relatedTaskData) {
        const newRelationship = await apiService.postData('api/Task/taskRelationship', relatedTaskData);
        return newRelationship.data;
    }

    /**
     * Deletes a related task for this task
     * @param {TaskRelationshipDto} relatedTaskData
     * @returns {Promise<ServiceResponse<boolean>>}
     */
    async deleteRelatedTask (relatedTaskData) {
        const deleteSuccessful = await apiService.deleteData('api/Task/taskRelationship/' + relatedTaskData.reId);
        return deleteSuccessful.data;
    }

    async getTaskDescription (taskId) {
        const response = await apiService.getData('api/Task/' + taskId + '/description');
        return response.data;
    }

    /**
     * Get subscribers for a task
     * @param taskId {number}
     * @return {Promise<ServiceResponse<Array<TaskSubscriptionDto>>>}
     */
    async getSubscribersForTask (taskId) {
        const subscribers = await apiService.getData('api/Task/taskSubscriptions/' + taskId);
        return subscribers.data;
    }

    /**
     * Subscribe a user to a task
     * @param taskId {number}
     * @param userId {number}
     * @return {Promise<ServiceResponse<TaskSubscriptionDto>>}
     */
    async subscribeToTask (taskId, userId) {
        const subscription = await apiService.postData('api/Task/taskSubscriptions/subscribe/' + taskId + '/' + userId);
        return subscription.data;
    }

    /**
     * Unsubscribe a user from a task
     * @param taskId {number}
     * @param userId {number}
     * @return {Promise<ServiceResponse<TaskSubscriptionDto>>}
     */
    async unsubscribeFromTask (taskId, userId) {
        const deletedSubscription = await apiService.deleteData('api/Task/taskSubscriptions/unsubscribe/' + taskId + '/' + userId);
        return deletedSubscription.data;
    }

    /**
     * Check if user is a subscriber
     * @param taskId {number}
     * @param userId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async checkIfSubscriber (taskId, userId) {
        const isSubscriber = await apiService.getData('api/Task/taskSubscriptions/' + taskId + '/' + userId);
        return isSubscriber.data;
    }

    /**
     * Gets available users that can interact with the task
     * @param taskId {number}
     * @return {Promise<ServiceResponse<Array<BasicUserDto>>>}
     */
    async getAvailableUsersForTask (taskId) {
        const availableUsers = await apiService.getData('api/Task/usersAvailableForTask/' + taskId);
        return availableUsers.data;
    }

    /**
     * Retrieves the completed recurrences for a specific task.
     * @param taskId {number}
     * @returns {Promise<ServiceResponse<Array<TaskRecurrenceDto>>>}
     */
    async getCompletedRecurrencesForTask(taskId) {
        const recurrences = await apiService.getData(`api/Task/recurring-tasks/task/${taskId}`);
        return recurrences.data;
    }

    /**
     * Sends a request to copy the task
     * @param taskId {string}
     * @return {Promise<ServiceResponse<number>>}
     */
    async copyTask(taskId) {
        const copy = await apiService.postData(`api/Task/Copy/Task/${taskId}`, null);

        return copy.data;
    }

    /**
     * Gets the task history for a task
     * @param taskId {number}
     * @return {Promise<ServiceResponse<TaskHistoryDto>>}
     */
    async getTaskHistory(taskId) {
        const response = await apiService.getData(`api/Task/history/${taskId}`);

        return response.data;
    }

    /**
     * Gets custom columns for new category
     * @param taskId {string}
     * @param categoryId {number}
     * @return {Promise<ServiceResponse<Array<CustomColumnDto>>>}
     */
    async getCustomColumnsForCategoryChange(taskId, categoryId) {
        const response = await apiService.getData(`api/Task/${taskId}/newCategory/${categoryId}`);

        return response.data;
    }

    async getNextRecurrenceForChange(taskId, recurrenceString) {
        const response = await apiService.getData(`api/Task/nextRecurrence/task/${taskId}/recurrence/${recurrenceString}`);

        return response.data;
    }

    /**
     * Updates a comment
     * @param taskChangeId {number}
     * @param updatedText {TaskHistoryDto}
     * @return {Promise<ServiceResponse<TaskHistoryDto>>}
     */
    async updateTaskChange(taskChangeId, updatedText) {
        const response = await apiService.putData(`api/Task/taskComment/${taskChangeId}`, updatedText.taskChange);

        return response.data;
    }

    /**
     * Deletes a task comment
     * @param taskChangeId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async deleteTaskChange(taskChangeId) {
        const response = await apiService.deleteData(`api/Task/taskComment/${taskChangeId}`);

        return response.data;
    }
}
