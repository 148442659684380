import React, { useEffect, useMemo, useState } from 'react';
import { FormControl, FormLabel, TextField, Grid, Button, Chip } from '@mui/material';
import Select from '@mui/material/Select';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MenuItem from '@mui/material/MenuItem';
import * as taskDetailConstants from '../constants/taskDetailConstants';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TaskButtonBar from './TaskDetailComponents/TaskButtonBar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ConfirmDeleteDialog from './Modal/ConfirmDelete';
import TaskHistoryButtonBar from './TaskDetailComponents/TaskHistoryButtonBar';
import FileUploadModal from './Modal/FileUploadModal';
import fileUploadService from '../api/fileUploadService';
import TaskApiService from '../api/TaskApiService';
import CircularProgress from '@mui/material/CircularProgress';
import TaskRelationshipModal from './Modal/TaskRelationshipModal';
import PropTypes from 'prop-types';
import authenticationManager from '../auth/AuthenticationManager';
import TaskSubscriptionModal from './Modal/TaskSubscriptionModal';
import './Tasks/Tasks.css';
import TaskComment from './TaskDetailComponents/TaskComment';
import TaskAttachment from './TaskDetailComponents/TaskAttachment';
import dayjs from 'dayjs';
import EditTaskRecurrenceModal from './Modal/RecurringTasks/EditTaskRecurrenceModal';
import {
    convertRecurrenceStringToDescription,
} from '../constants/RecurringTaskConstants';
import Typography from '@mui/material/Typography';
import RecurrencePastCompletionsModal from './Modal/RecurringTasks/RecurrencePastCompletionsModal';
import { Textarea } from '@mui/joy';
import StatusApiService from '../api/StatusApiService';
import UserApiService from '../api/UserApiService';
import CategoryApiService from '../api/CategoryApiService';
import PriorityApiService from '../api/PriorityApiService';
import OrgApiService from '../api/OrgApiService';
import TaskHistoryDto from '../dtos/Task/TaskHistoryDto';
import LocationApiService from '../api/LocationApiService';
import TaskRecurrenceDto from '../dtos/Task/TaskRecurrenceDto';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const SHOW_INLINE_IMAGES = 'Show inline images';
const SHOW_CHANGE_HISTORY = 'Show change history';
const HIDE_INLINE_IMAGES = 'Hide inline images';
const HIDE_CHANGE_HISTORY = 'Hide change history';
const HISTORY_TYPE_ATTACHMENT = 'file';
const editorConfiguration = {
    // plugins: [ Essentials, Bold, Italic, Paragraph ],
    // toolbar: [ 'bold', 'italic' ],
    // height: '400px'
};

const taskApiService = new TaskApiService();
const statusApiService = new StatusApiService();
const categoryApiService = new CategoryApiService();
const priorityApiService = new PriorityApiService();
const orgApiService = new OrgApiService();
const locationApiService = new LocationApiService();
const userApiService = new UserApiService();

const Task = (props) => {
    const { setSnackbarProperties } = props;
    const params = useParams();
    const [taskId] = useState(params.taskId);
    const [nextTaskId, setNextTaskId] = useState();
    const [previousTaskId, setPreviousTaskId] = useState();
    const [taskLoading, setLoading] = useState(true);
    const [taskSaving, setTaskSaving] = useState(false);

    const [currentComment, setCurrentComment] = useState('');
    const [taskHistory, setTaskHistory] = useState([]);
    const [changeHistoryButtonText, setChangeHistoryButtonText] = useState(SHOW_CHANGE_HISTORY);
    const [inlineImagesButtonText, setInlineImagesButtonText] = useState(HIDE_INLINE_IMAGES);
    const [showImages, setShowImages] = useState(true);
    const [showHistory, setShowHistory] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [openRelationshipModal, setShowRelationshipModal] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [showSubscribersModal, setShowSubscribersModal] = useState(false);
    const [showEditRecurrenceModal, setShowEditRecurrenceModal] = useState(false);
    const [showPastRecurrencesModal, setShowPastRecurrencesModal] = useState(false);
    const [showRecurrenceFileUploadModal, setShowRecurrenceFileUploadModal] = useState(false);
    const [showConfirmCopy, setShowConfirmCopy] = useState(false);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [availablePriorities, setAvailablePriorities] = useState([]);
    const [availableOrganizations, setAvailableOrganizations] = useState([]);
    const [availableStatuses, setAvailableStatuses] = useState([]);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [currentOrganization, setCurrentOrganization] = useState();
    const [componentKey, setComponentKey] = useState(0);
    const [recurringDueDate, setRecurringDueDate] = useState('');

    const [currentTask, setCurrentTask] = useState();
    const [originalTask, setOriginalTask] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const navigate = useNavigate();

    const isSuperAdmin = authenticationManager.isSuperAdmin();
    const isAdmin = authenticationManager.isAdmin();
    const userId = authenticationManager.getUserId();
    const usersOrgId = authenticationManager.getUsersOrgId();
    const projectUserXRefs = authenticationManager.getProjectUserXRefs();

    const hasChanges = useMemo(() => JSON.stringify(originalTask) !== JSON.stringify(currentTask) || taskId === '0' || taskId === '', [currentTask, originalTask]);
    const isRecurringTask = useMemo(() => currentTask?.category?.recurring ?? false, [currentTask?.category?.recurring]);
    const taskRecurrenceId = useMemo(() =>
        currentTask && currentTask.taskRecurrences && currentTask.taskRecurrences.length > 0 ?
            (currentTask.taskRecurrences.length === 1 ? currentTask.taskRecurrences[0].id :
            currentTask.taskRecurrences.find((recurrence) => !recurrence.completed).id)
            :
            0, [currentTask]);

    const recurrenceString = useMemo(() =>
        currentTask && currentTask.taskRecurrences && currentTask.taskRecurrences.length > 0 ?
            (currentTask.taskRecurrences.length === 1 ? currentTask.taskRecurrences[0].recurrenceString :
            currentTask.taskRecurrences.find((recurrence) => !recurrence.completed).recurrenceString)
            :
            '', [currentTask]);

    const recurrenceDescriptionText = useMemo(() => {
        if(isRecurringTask) {
            return convertRecurrenceStringToDescription(recurrenceString);
        }
        return '';
    }, [recurrenceString]);

    const canModifyFields = useMemo(() => {
            return (isSuperAdmin ||
            (currentTask?.reportedUserId === userId) ||
            (projectUserXRefs && projectUserXRefs.length > 0 && projectUserXRefs?.find((permission) => permission.projectId === currentTask?.projectId)?.permissionLevel > 1));
    }
    ,[projectUserXRefs, isSuperAdmin, currentTask?.reportedUserId]);

    async function handleCopyTask (confirmed) {
        if(confirmed) {
            const newTask = await taskApiService.copyTask(taskId);

            if(newTask && newTask.success) {
                setSnackbarProperties(`Successfully copied task with Task Id of ${newTask.data}`);
            } else if(newTask && newTask.message) {
                setSnackbarProperties(`Error copying task: ${newTask.message}`, 'error');
            } else {
                setSnackbarProperties(`Error copying task: ${newTask}`, 'error');
            }
        }

        setShowConfirmCopy(false);
    }

    function showCopyTaskDialog() {
        setShowConfirmCopy(true);
    }

    function handlePrintTask () {
        window.open('/PrintTask/' + taskId);
    }

    function handleSubscribers () {
        setShowSubscribersModal(true);
    }

    function handleCloseSubscribers () {
        setShowSubscribersModal(false);
    }

    async function handleGetNotifications () {
        if (isSubscribed) {
            const unsubscribe = await taskApiService.unsubscribeFromTask(taskId, userId);
            if (unsubscribe.success) {
                setIsSubscribed(false);
                setSnackbarProperties('Successfully Unsubscribed!', 'warning');
            } else {
                setSnackbarProperties(unsubscribe.message, 'error');
            }
        } else {
            const subscribe = await taskApiService.subscribeToTask(taskId, userId);
            if (subscribe.success) {
                setIsSubscribed(true);
                setSnackbarProperties('Successfully Subscribed!');
            } else {
                setSnackbarProperties(subscribe.message, 'error');
            }
        }
    }

    function handleRelationships () {
        setShowRelationshipModal(true);
    }

    function handleCloseRelationships () {
        setShowRelationshipModal(false);
    }

    function handleSendEmail () {

    }

    function handleAddAttachment () {
        setShowUploadModal(true);
    }

    function handleNewTask () {
        window.location.href = '/Task/';
    }

    function compareTaskHistory (a, b) {
        if (a.taskChangeTime > b.taskChangeTime) {
            return -1;
        }
        if (a.taskChangeTime < b.taskChangeTime) {
            return 1;
        }
        return 0;
    }

    async function onDeleteClick (value) {
        if (value) {
            const result = await taskApiService.deleteTask(taskId);
            if (result) {
                navigate('/Tasks');
            }
        } else {
            setShowDeleteModal(false);
        }
    }

    function handleFileUpload (event) {
        if (event) {
            fileUploadService.uploadTaskAttachment(event, taskId).then(() => {
                window.location.href = '/Task/' + taskId;
            });
        }

        setShowUploadModal(false);
    }

    function handleRecurrenceFileUpload(event) {
        if (event) {
            fileUploadService.uploadAttachmentForTaskRecurrence(event, taskRecurrenceId).then(() => {
                window.location.href = `/Task/${taskId}`;
            });
        }
        setShowRecurrenceFileUploadModal(false);
    }

    /**
     *
     * @param customColumn {TaskCustomColumnDto}
     */
    function renderCustomColumn(customColumn) {
        return(
            <Grid item xs={12} sx={gridItemSx}>
                <Grid item>
                    <FormLabel>{customColumn.customColumn.customColumnName}</FormLabel>
                </Grid>
                <Grid item xs={12}>
                    {renderCustomColumnData(customColumn)}
                </Grid>
            </Grid>
        );
    }

    function getUsernameById(userId) {
        const username = availableUsers.find((user) => user.userId === parseInt(userId));
        return username?.username ?? '';
    }

    function renderCustomColumnData(customColumn) {
        function updateValue (newValue, customColumnId) {
            const thisTask = {...currentTask};

            thisTask.customColumnData.find((ccd => ccd.customColumn.customColumnId === customColumnId)).columnValue = newValue;

            setCurrentTask(thisTask);
        }

        switch (customColumn.customColumn.inputType.toLowerCase()) {
        case(taskDetailConstants.DATE):
            return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        views={['year', 'month', 'day']}
                        disabled={!canModifyFields}
                        inputFormat="MM/DD/YYYY"
                        defaultValue={dayjs(new Date())}
                        sx={{ width: '100%' }}
                        value={new Date(customColumn.columnValue)}
                        onChange={(newValue) => {
                            updateValue(newValue, customColumn.customColumn.customColumnId)
                        }}
                        renderInput={(params) => <TextField {...params} error={false}/>}
                    /></LocalizationProvider>
            );
        case(taskDetailConstants.OPTION):
            console.log(customColumn);
            if(customColumn.customColumn.options === '%users%') {
                return(<Select
                    multiple
                    displayEmpty
                    renderValue={(selected) => {
                        return (
                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                {selected.map((value) => <Chip key={value} label={getUsernameById(value)}/>)}
                            </Box>
                        )
                    }}
                    disabled={!canModifyFields}
                    sx={{width: '100%'}}
                    value={customColumn.columnValue.split(',')}
                    onChange={(event) => {
                        console.log(event.target.value.reduce((accumulator, currentValue) => accumulator === '' ? `${currentValue}` : `${accumulator},${currentValue}`, ''));
                        updateValue(event.target.value.reduce((accumulator, currentValue) => accumulator === '' ? `${currentValue}` : `${accumulator},${currentValue}`, ''), customColumn.customColumn.customColumnId);
                    }}
                >
                    {
                        availableUsers.map((user) => <MenuItem key={user.userId} value={user.userId}>{user.username}</MenuItem>)
                    }
                </Select>);

            } else {
                return(
                    <Select
                        disabled={!canModifyFields}
                        sx={{width: '100%'}}
                        value={customColumn.columnValue}
                        onChange={(event) => {
                            updateValue(event.target.value, customColumn.customColumn.customColumnId);
                        }}
                    >
                        {customColumn.customColumn.options.toLowerCase() === '%user%' ?
                            availableUsers.map((user) => <MenuItem key={user.userId} value={user.username}>{user.username}</MenuItem>) :
                            customColumn.customColumn.options.split('|').map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem> )}
                    </Select>
                );
            }
        case(taskDetailConstants.TEXT_FIELD):
            return(
                <TextField
                    disabled={!canModifyFields}
                    sx={{width: '100%'}}
                    value={customColumn.columnValue}
                    onChange={(event) => updateValue(event.target.value, customColumn.customColumn.customColumnId)}
                />
            );
        case(taskDetailConstants.INT):
            return(
                <TextField
                    disabled={!canModifyFields}
                    value={customColumn.columnValue}
                    type={'number'}
                    onChange={(event) => updateValue(event.target.value, customColumn.customColumn.customColumnId)}
                />
            );
        case(taskDetailConstants.TEXT_AREA):
            return(
                <Textarea
                    disabled={!canModifyFields}
                    value={customColumn.columnValue}
                    onChange={(event) => updateValue(event.target.value, customColumn.customColumn.customColumnId)}
                    minRows={3}
                />
            );
        default:
            return (
                <TextField
                    disabled={!canModifyFields}
                    sx={{width: '100%'}}
                    value={customColumn.columnValue}
                    onChange={(event) => updateValue(event.target.value, customColumn.customColumn.customColumnId)}
                />
            );
        }
    }

    function renderTaskHistory(historyItem, index) {
        return(
            historyItem.taskChangeType === HISTORY_TYPE_ATTACHMENT ?
                <TaskAttachment
                    showImages={showImages}
                    key={index}
                    taskChange={historyItem}
                />
                :
                <TaskComment
                    setSnackbarProperties={setSnackbarProperties}
                    handleDeleted={() => {
                        let copyTaskHistory = [...taskHistory];
                        const thisHistoryIndex = copyTaskHistory.findIndex((history) => history.taskChangeId === historyItem.taskChangeId);
                        copyTaskHistory.splice(thisHistoryIndex, 1);

                        setTaskHistory(copyTaskHistory);
                    }}
                    showHistory={showHistory}
                    key={index}
                    taskChange={historyItem}
                    setTaskChange={(value) => {
                        const copyTaskHistory = [...taskHistory];
                        const thisHistory = copyTaskHistory.find((history) => history.taskChangeId === historyItem.taskChangeId);
                        thisHistory.taskChange = value;
                        setTaskHistory(copyTaskHistory);
                    }}
                />
        );
    }

    function showDeleteDialog () {
        setShowDeleteModal(true);
    }

    async function handleOrganizationChange(event) {
        if(isSuperAdmin) {
            const response = await locationApiService.getFullLocationsForOrg(event.target.value);
            setAvailableProjects(response.data);
            setCurrentOrganization(event.target.value);

            const selectableUsers = await userApiService.getSelectableUsersForOrg(event.target.value);

            setAvailableUsers(selectableUsers.data);
        }
    }

    function handleProjectChange(event) {
        const thisTask = {...currentTask};
        const selectedProject = availableProjects.find((project) => project.pjId === event.target.value);
        thisTask.project = selectedProject;
        thisTask.projectId = event.target.value;

        setCurrentTask(thisTask);
    }

    async function handleCategoryChange(event) {
        const thisTask = {...currentTask};
        thisTask.categoryId = event.target.value;
        const selectedCategory = availableCategories.find((category) => category.ctId === event.target.value);
        thisTask.category = selectedCategory;

        const taskIdToSend = parseInt(taskId) > 0 ? taskId : 0;

        const newCustomColumns = await taskApiService.getCustomColumnsForCategoryChange(taskIdToSend, event.target.value);

        thisTask.customColumnData = newCustomColumns.data;

        setCurrentTask(thisTask);
    }

    function handlePriorityChange(event) {
        const thisTask = {...currentTask};
        thisTask.priorityId = event.target.value;
        const selectedPriority = availablePriorities.find((priority) => priority.prId === event.target.value);
        thisTask.priority = selectedPriority;
        setCurrentTask(thisTask);
    }

    async function saveTaskComment() {
        const newComment = new TaskHistoryDto();
        newComment.taskChange = currentComment;
        newComment.taskId = taskId;
        newComment.taskChangeType = 'comment';
        newComment.taskChangeUser = '';
        const response = await taskApiService.addTaskComment(newComment);

        if(response) {
            const currentHistory = [...taskHistory];
            currentHistory.push(response);
            setTaskHistory(currentHistory.sort(compareTaskHistory));
            setCurrentComment('');
            setSnackbarProperties('Comment Saved');
            setComponentKey(componentKey === 100 ? 0 : componentKey + 1);
        } else {
            setSnackbarProperties('Error adding comment', 'error');
        }
    }

    function handleInlineImagesButton() {
        setShowImages(!showImages);
        setInlineImagesButtonText(inlineImagesButtonText === SHOW_INLINE_IMAGES ? HIDE_INLINE_IMAGES : SHOW_INLINE_IMAGES);
    }

    function handleChangeHistoryButton() {
        setShowHistory(!showHistory);
        setChangeHistoryButtonText(changeHistoryButtonText === SHOW_CHANGE_HISTORY ? HIDE_CHANGE_HISTORY : SHOW_CHANGE_HISTORY);
    }

    function handleRecurrenceNotesChange(event) {
        const thisTask = {...currentTask};

        const currentRecurrence = thisTask.taskRecurrences.find((recurrence) => !recurrence.completed);
        currentRecurrence.completionNotes = event.target.value;

        setCurrentTask(thisTask);
    }

    async function handleRecurrenceStringChange(value) {
        const thisTask = {...currentTask};

        const noPreviousRecurrence = thisTask.taskRecurrences.length === 0;

        const newDueDate = await taskApiService.getNextRecurrenceForChange(taskId, value);
        setRecurringDueDate(newDueDate.data.toString().split('T')[0]);

        if(noPreviousRecurrence) {
            const newRecurrence = new TaskRecurrenceDto();
            newRecurrence.recurrenceString = value;
            newRecurrence.taskId = taskId;
            newRecurrence.due = newDueDate.data;

            thisTask.taskRecurrences.push(newRecurrence);
        } else {
            const currentRecurrence = thisTask.taskRecurrences.length > 0 ? thisTask.taskRecurrences.find((recurrence) => !recurrence.completed) : new TaskRecurrenceDto();

            currentRecurrence.recurrenceString = value;
        }

        setCurrentTask(thisTask);
    }

    function getCurrentCompletionNotes() {
        const currentRecurrence = currentTask.taskRecurrences.find((recurrence) => !recurrence.completed);
        return currentRecurrence.completionNotes;
    }

    async function saveTask() {
        setTaskSaving(true);
        const isNewTask = !(parseInt(taskId) > 0);
        if(!isNewTask && JSON.stringify(originalTask) === JSON.stringify(currentTask)) {
            setSnackbarProperties('No changes detected to task', 'error');
            return;
        }

        if(!validateTask()) {
            setSnackbarProperties('Validation Failed', 'error');
        }

        if(isNewTask) {
            const newTask = await taskApiService.addNewTask(currentTask);

            if(newTask) {
                window.location.href=`/Task/${newTask.id}`;
            }
        } else {
            const response = await taskApiService.updateTask(taskId, currentTask);

            if(response && response.success) {
                setSnackbarProperties('Saved changes');
                setOriginalTask(JSON.parse(JSON.stringify(currentTask)));

                const newTaskHistoryResponse = await taskApiService.getTaskHistory(taskId);

                setTaskHistory(newTaskHistoryResponse.data);
            } else {
                setSnackbarProperties(`Error during save: ${response ? (response.message ? response : response.message) : ''}`, 'error');
            }
        }

        setTaskSaving(false);
    }

    function validateTask() {
        return true;
    }

    async function loadTask() {
        let response;
        if(parseInt(taskId) > 0) {
            response = await taskApiService.getTask(taskId);
            if(response.message === 'Unauthorized') {
                setSnackbarProperties('Either this task does not exist or you are not authorized to view it', 'error');
                navigate('/Tasks');
            }
            if(isSuperAdmin) {
                setCurrentOrganization(response.data.project.pjOrgDto.ogId);
            }
        } else {
            response = await taskApiService.getNewTask();
        }
        setOriginalTask(response.data);
        if(response.data.isRecurring) {
            setRecurringDueDate(response.data && response.data.taskRecurrences && response.data.taskRecurrences.length > 0 ?
                (response.data.taskRecurrences.length === 1 ? response.data.taskRecurrences[0].due.toString().split('T')[0] :
                response.data.taskRecurrences.find((recurrence) => !recurrence.completed).due.toString().split('T')[0])
                :
                '');
        }
        setCurrentTask(JSON.parse(JSON.stringify(response.data)));
    }

    async function loadCategories() {
        const categories = await categoryApiService.GetCategories();
        setAvailableCategories(categories.data);
    }

    async function loadPriorities() {
        const priorities = await priorityApiService.GetPriorities();
        setAvailablePriorities(priorities.data);
    }

    const getAvailableUsers = async () => {
        if(parseInt(taskId) > 0) {
            const response = await taskApiService.getAvailableUsersForTask(taskId);
            if (response.success) {
                setAvailableUsers(response.data);
            } else {
                setSnackbarProperties('Unable to populate list of users: ' + response.message, 'error');
            }
        } else {
            const response = await userApiService.getUsersForNewTask();
            if(response.success) {
                setAvailableUsers(response.data);
            } else {
                setSnackbarProperties(`Unable to populate list of users: ${response.message}`, 'error');
            }
        }
    }

    async function loadStatuses() {
        const response = await statusApiService.getStatuses();
        setAvailableStatuses(response.data);
    }

    async function loadOrganizations() {
        const response = await orgApiService.getOrgs();
        setAvailableOrganizations(response.data);
    }

    async function loadTaskHistory() {
        if(parseInt(taskId) > 0) {
            const response = await taskApiService.getTaskHistory(parseInt(taskId));
            setTaskHistory(response.data);
        }
    }

    async function loadProjects() {
        let response;
        if(parseInt(taskId) > 0) {
            response = await locationApiService.getPossibleFullLocationsForTask(taskId);
        } else {
            response = await locationApiService.getPossibleFullLocationsForNewTask();
        }

        setAvailableProjects(response.data);
    }

    async function loadNextTaskId() {
        if(parseInt(taskId) > 0) {
            const response = await taskApiService.getNextTaskId(taskId);

            setNextTaskId(response);
        }
    }

    async function loadPreviousTaskId() {
        if(parseInt(taskId) > 0) {
            const response = await taskApiService.getPreviousTaskId(taskId);

            setPreviousTaskId(response);
        }
    }

    useEffect(() => {
        Promise.all([
            loadPriorities(),
            loadCategories(),
            loadTask(),
            getAvailableUsers(),
            loadStatuses(),
            loadTaskHistory(),
            loadProjects(),
            loadNextTaskId(),
            loadPreviousTaskId()
        ]).then(() => setLoading(false));
        if(isSuperAdmin) {
            void loadOrganizations();
        }

    }, [taskId, componentKey]);

    const gridItemSx = { marginBottom: '30px'}

    return (
        <div>
            {taskLoading ?
                <div
                    style={{
                        position: 'fixed', /* or absolute */
                        top: '50%',
                        left: '50%',
                        display: taskLoading ? 'flex' : 'none',
                    }}
                >
                    <CircularProgress/>
                </div>
                :
                <div>
                    <div
                        style={{
                            display: taskLoading ? 'none' : 'block',
                            margin: 'auto',
                        }}
                    >
                        <center>
                            <TaskButtonBar
                                previousTaskId={previousTaskId}
                                nextTaskId={nextTaskId}
                                onDeleteClick={showDeleteDialog}
                                handleCopyTask={showCopyTaskDialog}
                                handlePrintTask={handlePrintTask}
                                handleSubscribers={handleSubscribers}
                                handleGetNotifications={handleGetNotifications}
                                handleRelationships={handleRelationships}
                                handleSendEmail={handleSendEmail}
                                handleAddAttachment={handleAddAttachment}
                                currentTaskId={taskId === '' || taskId === '0' ? 0 : parseInt(taskId)}
                                handleNewTask={handleNewTask}
                                isAdmin={isAdmin}
                                isSubscribed={isSubscribed}
                            />
                        </center>
                    </div>
                    <Box border={1} borderColor="grey.500" padding={2} sx={{margin: '10px', width: '100%'}}>
                        <Grid container>
                            <Grid item xs={12} sx={gridItemSx}>
                                <Grid item xs={3}>
                                    <FormLabel>{parseInt(taskId) > 0 ? `Task ID: ${taskId}` : 'New Task Description'}</FormLabel>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        disabled={!canModifyFields}
                                        sx={{width: '100%'}}
                                        value={currentTask?.shortDescription ?? ''}
                                        onChange={(event) => {
                                            const thisTask = {...currentTask};
                                            thisTask.shortDescription = event.target.value;
                                            setCurrentTask(thisTask);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {taskId > 0 &&
                                <Grid item xs={12} sx={gridItemSx}>
                                    <FormLabel>Created by {currentTask?.reportedUser.username ?? ''} on {currentTask?.reportedDate ?? ''}</FormLabel>
                                </Grid>
                            }
                            {isSuperAdmin &&
                                <Grid item xs={12} sx={gridItemSx}>
                                    <Grid item xs={3}>
                                        <FormLabel>Organization</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            disabled={!canModifyFields}
                                            sx={{width: '100%'}}
                                            value={currentOrganization}
                                            onChange={handleOrganizationChange}
                                        >
                                            {availableOrganizations.map((org, index) => <MenuItem key={index} value={org.ogId}>{org.ogName}</MenuItem>)}
                                        </Select>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12} sx={gridItemSx}>
                                <Grid item xs={3}>
                                    <FormLabel>Project/Location</FormLabel>
                                </Grid>
                                <Grid>
                                    <Select
                                        disabled={!canModifyFields}
                                        sx={{width: '100%'}}
                                        value={currentTask?.project?.pjId ?? 0}
                                        onChange={handleProjectChange}
                                    >
                                        {availableProjects?.map((project, index) => <MenuItem key={index} value={project.pjId}>{project.pjName}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={gridItemSx}>
                                <Grid item xs={3}>
                                    <FormLabel>Category</FormLabel>
                                </Grid>
                                <Grid item>
                                    <Select
                                        disabled={!canModifyFields}
                                        sx={{width: '100%'}}
                                        value={currentTask?.categoryId}
                                        onChange={handleCategoryChange}
                                    >
                                        {availableCategories.sort((a, b) => a.ctSortSeq - b.ctSortSeq).map((category, index) => <MenuItem key={index} value={category.ctId}>{category.ctName}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={gridItemSx}>
                                <Grid item xs={3}>
                                    <FormLabel>Priority</FormLabel>
                                </Grid>
                                <Grid item>
                                    <Select
                                        disabled={!canModifyFields}
                                        sx={{width: '100%'}}
                                        value={currentTask?.priorityId}
                                        onChange={handlePriorityChange}
                                    >
                                        {availablePriorities.sort((a, b) => a.prSortSeq - b.prSortSeq).map((priority, index) => <MenuItem key={index} value={priority.prId}>{priority.prName}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={gridItemSx}>
                                <Grid item xs={3}>
                                    <FormLabel>Assigned to</FormLabel>
                                </Grid>
                                <Grid>
                                    <Select
                                        disabled={!canModifyFields}
                                        sx={{width: '100%'}}
                                        value={currentTask?.assignedToUserId}
                                        onChange={(event) => {
                                            const thisTask = {...currentTask};
                                            thisTask.assignedToUserId = event.target.value;
                                            setCurrentTask(thisTask);
                                        }}
                                    >
                                        {availableUsers.map((user, index) => <MenuItem key={index} value={user.userId}>{user.username}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={gridItemSx}>
                                <Grid item xs={3}>
                                    <FormLabel>Status</FormLabel>
                                </Grid>
                                <Grid item>
                                    <Select
                                        disabled={!canModifyFields}
                                        sx={{width: '100%'}}
                                        value={currentTask?.statusId}
                                        onChange={(event) => {
                                            const thisTask = {...currentTask};
                                            thisTask.statusId = event.target.value;
                                            setCurrentTask(thisTask);
                                        }}
                                    >
                                        {availableStatuses.sort((a,b) => a.stSortSeq - b.stSortSeq).map((status, index) => <MenuItem key={index} value={status.stId}>{status.stName}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Grid>
                            {currentTask?.customColumnData.map((customColumn) => renderCustomColumn(customColumn))}
                        </Grid>
                        {isRecurringTask &&
                            <div>
                                <Grid item style={gridItemSx}>
                                    <Grid item>
                                        <FormLabel>Recurrence</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} sx={{display: 'flex'}}>
                                        <Button disabled={!canModifyFields} variant={'contained'} onClick={() => setShowEditRecurrenceModal(true)}>Set Recurrence</Button>
                                        <Typography sx={{fontStyle: 'italic', marginTop: '5px', marginLeft: '5px'}}>{recurrenceDescriptionText}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item style={gridItemSx}>
                                    <Grid item>
                                        <FormLabel>Due Date</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <FormLabel>{recurringDueDate}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid item style={gridItemSx}>
                                    <Grid item>
                                        <FormLabel>Current Recurrence Notes</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Textarea
                                            disabled={!canModifyFields}
                                            minRows={2}
                                            sx={{width: '100%'}}
                                            value={getCurrentCompletionNotes}
                                            onChange={handleRecurrenceNotesChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item style={gridItemSx}>
                                    <Grid item>
                                        <FormLabel>Attachments for Current Recurrence</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        {currentTask.taskRecurrences.find((recurrence) => !recurrence.completed)?.attachments?.map((attachment) => {
                                            return(<FormControl sx={{marginLeft: '30px'}}>
                                                <a href={attachment.url} target={'_blank'} rel={'noreferrer'}>{attachment.filename}</a>
                                            </FormControl>);
                                        })}
                                        {(currentTask.taskRecurrences.find((recurrence) => !recurrence.completed)?.attachments?.length === 0) ?
                                        <FormLabel sx={{marginLeft: '30px'}}>No Attachments</FormLabel> : null
                                        }
                                    </Grid>
                                    <Grid item>
                                        <Button disabled={!canModifyFields || !(parseInt(taskId) > 0)} sx={{marginLeft: '30px', marginTop: '20px'}} variant={'contained'} onClick={() => setShowRecurrenceFileUploadModal(true)}>Add New Attachment</Button>
                                    </Grid>
                                </Grid>
                                <Grid item style={gridItemSx}>
                                    <Grid item>
                                        <FormLabel>Past Completions</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Button variant={'contained'} onClick={() => setShowPastRecurrencesModal(true)}>Get Past Completions List</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        <Grid xs={12}>
                            <Button variant={'contained'} sx={{width: '100%'}} disabled={!hasChanges || taskSaving} onClick={saveTask}>{taskSaving ? 'Saving...' : 'Save'}</Button>
                        </Grid>
                    </Box>

                    {taskId !== undefined && parseInt(taskId) !== 0 && !taskLoading &&
                        <div>
                            <Grid container>
                                <Grid item sx={{width: '100%', marginLeft: '10px'}}>
                                    <CKEditor
                                        style={{height: '400px', width: '100%'}}
                                        editor={ClassicEditor}
                                        config={editorConfiguration}
                                        onReady={editor => {
                                            editor.editing.view.change(writer => {
                                                writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
                                            });
                                        }}
                                        data={currentComment}
                                        onChange={(event, editor) => {
                                            setCurrentComment(editor.getData());
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{marginTop: '20px', marginLeft:'10px'}}
                                        variant={'contained'}
                                        onClick={saveTaskComment}
                                        disabled={currentComment === ''}
                                    >
                                        Save Comment
                                    </Button>
                                </Grid>
                            </Grid>
                                <TaskHistoryButtonBar
                                    style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '50px' }}
                                    handleInlineImagesButton={handleInlineImagesButton}
                                    inlineImagesButtonText={inlineImagesButtonText}
                                    handleChangeHistoryButton={handleChangeHistoryButton}
                                    changeHistoryButtonText={changeHistoryButtonText}
                                />
                            {taskHistory.map((history, index) => {
                                return renderTaskHistory(history, index);
                            })}
                        </div>
                    }
                    <ConfirmDeleteDialog
                        id="confirm-delete"
                        keepMounted
                        open={showDeleteModal}
                        onClose={onDeleteClick}
                    />
                    <FileUploadModal
                        onClose={handleFileUpload}
                        open={showUploadModal}
                        imageButton={true}
                        acceptFile={handleFileUpload}
                    />
                    <FileUploadModal
                        onClose={handleRecurrenceFileUpload}
                        open={showRecurrenceFileUploadModal}
                        imageButton={true}
                        acceptFile={handleRecurrenceFileUpload}
                    />
                    <TaskRelationshipModal
                        onClose={handleCloseRelationships}
                        open={openRelationshipModal}
                        taskId={taskId === '' || taskId === '0' ? 0 : parseInt(taskId)}
                        setSnackbarProperties={setSnackbarProperties}
                    />
                    <TaskSubscriptionModal
                        onClose={handleCloseSubscribers}
                        open={showSubscribersModal}
                        taskId={parseInt(taskId)}
                        setSnackbarProperties={setSnackbarProperties}
                        availableUsers={availableUsers}
                    />
                    <EditTaskRecurrenceModal
                        open={showEditRecurrenceModal}
                        close={() => setShowEditRecurrenceModal(false)}
                        currentRecurrenceString={recurrenceString}
                        setRecurrenceString={handleRecurrenceStringChange}
                        setSnackbarProperties={setSnackbarProperties}
                    />
                    <RecurrencePastCompletionsModal
                        taskId={taskId}
                        open={showPastRecurrencesModal}
                        close={() => setShowPastRecurrencesModal(false)}
                        setSnackbarProperties={setSnackbarProperties}
                        taskApiService={taskApiService}
                    />
                    <ConfirmDeleteDialog
                        onClose={handleCopyTask}
                        open={showConfirmCopy}
                        content={'Are you sure you want to copy this task?'}
                        title={'Copy Task'}
                    />
                </div>
                }
        </div>
    );
};

Task.propTypes = {
    setSnackbarProperties: PropTypes.func,
};
export default Task;
